import React from "react";

import GetStartedBlock from "../GetStartedBlock";
import Accent from "../Accent";

import GetStarted1Img from "./../../images/getstarted2.jpg";
import GetStarted2Img from "./images/GetStarted-2.jpg";
import GetStarted3Img from "./images/GetStarted-3.jpg";
import GetStarted4Img from "./../../images/getstarted1.jpg";
import GetStarted5Img from "./images/GetStarted-5.jpg";
import GetStarted6Img from "./images/GetStarted-6.jpg";
import GetStarted7Img from "./../../images/getstarted3.jpg";
import GetStarted8Img from "./images/GetStarted-8.jpg";
import styles from "./styles.module.less";

const GetStartedContent = () => {
    const GetStarted1Title = <>Figure out what you can&nbsp;afford</>;

    const GetStarted1Desc = (
        <>
            The most pragmatic of all the first-time homebuyer tips is
            establishing your budget. Every property is uniquely priced, so when
            buying your first home, use our{" "}
            <a href="/loan-calculators" style={{ fontWeight: 300 }}>
                affordability calculator
            </a>{" "}
            to refine your search by knowing what you can afford from the start.
            <br />
            <br />
        </>
    );

    const GetStarted2Desc = (
        <>
            It’s important to find a loan officer who can get you preapproved
            for your budgeted amount. You can then begin your search for your
            dream home knowing what you can afford. Sellers like buyers who are
            preapproved.
            <br />
            <br />
            Though the process is pretty straightforward, it does require that
            you gather documents such as identifications, bank statements, plus
            income and asset statements.
            <br />
            <br />
        </>
    );
    const GetStarted3Desc = (
        <>
            Let the house hunting begin, but don’t go it alone. Partnering with
            the right Realtor—one who understands your wants and can start
            researching right alongside you—can make a major difference in your
            home-buying experience and quality of home you end up in.
            <br />
            <br />
        </>
    );
    const GetStarted4Desc = (
        <>
            Once your research and Realtor have helped identify the home that’s
            just right for you, it’s time to put your preapproval to work; it’s
            time to make an official offer. Don’t worry, it’s a nervous time for
            everyone. Take a deep breath, trust your instincts and enjoy the
            moment.
            <br />
            <br />
        </>
    );
    const GetStarted5Desc = (
        <>
            You’ve found your home—now it’s time to turn your preapproval into
            an offer. Keep in mind, there are many first-time homebuyer programs
            to choose from, so you’ll want to work closely with your loan
            officer to{" "}
            <a href="/home-loans" style={{ fontWeight: 300 }}>
                find the program
            </a>
            <a href="/home-loans" style={{ fontWeight: 300 }}>
                that’s best for you
            </a>
            .<br />
            <br />
        </>
    );
    const GetStarted6Desc = (
        <>
            Buying a house is a long-term investment. And because no home is
            perfect, you’ll want to hire a professional home inspector to
            thoroughly evaluate the integrity of the property and to tell you
            about any potential problems.
            <br />
            <br />
        </>
    );
    const GetStarted7Desc = (
        <>
            The lender ultimately needs to determine if the house you’re buying
            is worth the price you’re paying. To do this, they perform an
            appraisal of the property to evaluate if the home’s value matches
            (or exceeds) the amount of the loan.
            <br />
            <br />
            If it doesn’t, then you’ll need to{" "}
            <a href="/find-your-loan-officer" style={{ fontWeight: 300 }}>
                talk to your loan officer
            </a>{" "}
            about your options.
            <br />
            <br />
        </>
    );
    const GetStarted8Desc = (
        <>
            Assuming the appraisal is favorable, then you’re just a few
            signatures away from making this house your home. These documents
            will legally bind you and the seller to the terms, as well as
            initiate the transferring of funds that makes your first-time
            homebuyer loan official. Congrats!
            <br />
            <br />
        </>
    );

    //<HTMLContent className={styles.subheadDescription} content={eightSteps} />
    //const eightSteps = ;

    return (
        <section>
            <section className={styles.GetStartedIntro}>
                <div className={styles.Container}>
                    <h1>8 steps to buying your first home</h1>
                    <Accent />
                    <p>
                        If you’re a first-time homebuyer who’s decided it’s time
                        to <em>stop</em> dreaming and <em>start</em> doing, then
                        we’re here to support you through the home-buying
                        process. After all, this is an exciting time.
                    </p>
                </div>
            </section>

            <GetStartedBlock
                subtitle="Step One"
                title={GetStarted1Title}
                description={GetStarted1Desc}
                href="#"
                icon={GetStarted1Img}
                flexD="row-reverse"
                alt="Figure Out What You Can Afford - Get Started Buying a Home | Bay Equity Home Loans"
            />
            <GetStartedBlock
                subtitle="Step Two"
                title="Get preapproved for your mortgage"
                description={GetStarted2Desc}
                href="#"
                icon={GetStarted2Img}
                flexD="row"
                alt="Get Preapproved for Your Mortgage - Get Started Buying a Home | Bay Equity Home Loans"
            />
            <GetStartedBlock
                subtitle="Step Three"
                title="Research real estate and Realtors"
                description={GetStarted3Desc}
                href="#"
                icon={GetStarted3Img}
                flexD="row-reverse"
                alt="Research Real Estate and Realtors - Get Started Buying a Home | Bay Equity Home Loans"
            />
            <GetStartedBlock
                subtitle="Step Four"
                title="Make an offer on the right house"
                description={GetStarted4Desc}
                href="#"
                icon={GetStarted4Img}
                flexD="row"
                alt="Make an Offer on the Right House - Get Started Buying a Home | Bay Equity Home Loans"
            />

            <GetStartedBlock
                subtitle="Step Five"
                title="Select your loan program"
                description={GetStarted5Desc}
                href="#"
                icon={GetStarted5Img}
                flexD="row-reverse"
                alt="Select Your Home Loan Program - Get Started Buying a Home | Bay Equity Home Loans"
            />
            <GetStartedBlock
                subtitle="Step Six"
                title="Have the house inspected"
                description={GetStarted6Desc}
                href="#"
                icon={GetStarted6Img}
                flexD="row"
                alt="Have the Home Inspected - Get Started Buying a Home | Bay Equity Home Loans"
            />

            <GetStartedBlock
                subtitle="Step Seven"
                title="Get the home appraised"
                description={GetStarted7Desc}
                href="#"
                icon={GetStarted7Img}
                flexD="row-reverse"
                alt="Have the Home Appraised - Get Started Buying a Home | Bay Equity Home Loans"
            />
            <GetStartedBlock
                subtitle="Step Eight"
                title="Sign the papers to close the sale"
                description={GetStarted8Desc}
                href="#"
                icon={GetStarted8Img}
                flexD="row"
                alt="Sign the Papers to Close the Sale - Get Started Buying a Home | Bay Equity Home Loans"
            />
        </section>
    );
};

export default GetStartedContent;
