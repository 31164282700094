import React from "react";
import { graphql } from "gatsby";
import { mapBlogs } from "../../lib/utility";
import BlogRoll from "../../components/BlogRoll";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import getStartedHero from "./images/hero.jpg";
import IntroBlock from "../../components/IntroBlock";
import HeroLarge from "../../components/HeroLarge";
import GetStartedContent from "../../components/GetStartedContent";
import FindLO from "../../components/FindLO";
import styles from "./styles.module.less";
import Helmet from "react-helmet";

const GetStartedPage = ({ data }) => {
    const header = <Header active={["get-started"]} />;
    const intro = "Every dream has a starting point";
    const par =
        "Purchasing a home can feel like a bunch of big decisions all at once, especially for a first-time homebuyer. Luckily, we work with homebuyers every day, answering all the big questions about homeownership, and we want to pass our experience, knowledge and home-buying tips on to you.";
    const par2 = (
        <span>
            <a href="/find-your-loan-officer" className={styles.Link}>
                Talk to one of our loan officers
            </a>{" "}
            to ask anything and everything you want to know about the
            home-buying process.
        </span>
    );

    const { corpBlogs } = data;
    const { edges: corpBlobPosts = [] } = corpBlogs || {};
    const corporateBlogs = mapBlogs(corpBlobPosts);

    return (
        <Layout hasHero={true} header={header}>
            <Helmet>
                <title>
                    Steps To Buying A Home - First-Time Homebuyer | Bay Equity
                    Home Loans
                </title>
                <meta
                    name="description"
                    content="Ready to start the home-buying process? Let Bay Equity’s loan officers help you figure out what you can afford and get you pre-approved. Contact us today."
                />
            </Helmet>
            <HeroLarge customPhoto={getStartedHero} />
            <IntroBlock header={intro} par={par} par2={par2} />
            <GetStartedContent />
            <FindLO />
            {corporateBlogs.length >= 3 && (
                <BlogRoll
                    corporateBlogs={corporateBlogs}
                    blogs={corporateBlogs}
                    showBlogs={true}
                    word="our"
                    url="getting-started"
                />
            )}
        </Layout>
    );
};

export default GetStartedPage;

export const pageQuery = graphql`
    query GetStartedPageQuery {
        corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: {
                type: { eq: "corporate" }
                tags: { in: ["Getting Started"] }
            }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    tags
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                    date
                    feature
                }
            }
        }
    }
`;
