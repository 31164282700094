import React from "react";

import styles from "./styles.module.less";

const Hero = ({ customPhoto, backgroundPosition = "center" }) => {
    let bg = {
        backgroundImage: `url(${customPhoto})`,
        backgroundPosition,
    };

    return (
        <section className={styles.HeroLarge}>
            <div style={bg} className={styles.hero} />
        </section>
    );
};

export default Hero;
