import React from "react";

import Accent from "../Accent";

import styles from "./styles.module.less";

const GetStartedBlock = ({
    icon,
    subtitle,
    title,
    description,
    flexD,
    alt,
}) => {
    return (
        <section className={styles.GetStartedBlock} data-background="dark">
            <div className={styles.container} style={{ flexDirection: flexD }}>
                <div className={styles.image}>
                    <img src={icon} alt={alt} />
                </div>
                <div className={styles.text}>
                    <h1 className={styles.title}>
                        <p>{subtitle}</p>
                        {title}
                    </h1>
                    <Accent align="left" />
                    <div className={styles.description}>{description}</div>
                </div>
            </div>
        </section>
    );
};

export default GetStartedBlock;

GetStartedBlock.defaultProps = {
    bgColor: "#FFFFFF",
    flexD: "row",
};
